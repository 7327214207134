import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import '../styles/components/Input.scss';
import ValidStates from '../utils/ValidStates';

const RoundedTextArea = ({
  id,
  value,
  type,
  placeholder,
  icon,
  callback,
  checkState,
  error,
  state,
  autoComplete,
  disabled,
}) => {
  const inputRef = useRef();
  const [focused, setFocused] = useState(false);
  const onFocus = () => {
    setFocused(true);
  };
  const onBlur = (e) => {
    setFocused(false);
    checkState(e);
  };
  const onBoxClick = () => {
    inputRef.current.focus();
  };
  const onChange = (e) => {
    callback(e);
    checkState(e);
  };
  return (
    <div className="rounded-form-field">
      <div
        role="button"
        tabIndex="0"
        onKeyDown={onBoxClick}
        onClick={onBoxClick}
        className={classNames([
          'rounded-form-field-input-rounded',
          focused && 'focused',
          state === ValidStates.Valid && 'valid',
          state === ValidStates.Invalid && 'invalid',
        ])}
      >
        <textarea
          ref={inputRef}
          onBlur={onBlur}
          onFocus={onFocus}
          id={id}
          name={id}
          type={type}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete={autoComplete}
          disabled={disabled}
        />
        {icon}
      </div>
      {error !== '' && <p className="input-error">{error}</p>}
    </div>
  );
};

RoundedTextArea.propTypes = {
  /** @param classes "classes" (required) is the
   * Styling Object that we wanna pass in. Please note for
   *  this You need to have a formType i.e defaultInput and
   *  classes that have defaultInput-input, -icon and -error
   *  in order for this to work */
  /** @param id "id" id of input field */
  id: PropTypes.string.isRequired,
  /** @param value "value" is the value displayed */
  value: PropTypes.string,
  /** @param placeholder "placeholder" temp text displayed */
  placeholder: PropTypes.string,
  /** @param icon "icon" is the icon to the right of the input field */
  icon: PropTypes.any,
  /** @param callback "callback" is the function that gets called back to the parent element on input change */
  callback: PropTypes.func,
  /** @param checkState "checkState" is the function that gets called back to the parent element on blur */
  checkState: PropTypes.func,
  /** @param error "error" error is a string this should only be set
   *  when there is an error else it should be a blank string */
  error: PropTypes.string,
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  state: PropTypes.oneOf([
    ValidStates.NotSet,
    ValidStates.Valid,
    ValidStates.Invalid,
  ]),
  disabled: PropTypes.bool,
};

RoundedTextArea.defaultProps = {
  value: '',
  placeholder: '',
  icon: '',
  type: 'text',
  callback: () => {},
  checkState: () => {},
  error: '',
  autoComplete: 'on',
  state: ValidStates.NotSet,
  disabled: false,
};

export default RoundedTextArea;
