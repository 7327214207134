import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Layout from "../components/layout"
import SEO from "../components/seo"
import '../styles/pages/contact.scss';
import RoundedInputField from '../components/RoundedInputField';
import ValidStates from '../utils/ValidStates';
import useFormHooks from '../utils/useForm.hooks';
import RoundedTextArea from '../components/RoundedTextArea';
import DefaultButton from '../components/DefaultButton';

const EmailSocial = () => (
  <svg width="38" height="30" viewBox="0 0 38 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33.75 30H3.75C1.67893 30 0 28.3211 0 26.25V3.58688C0.0873907 1.57978 1.741 -0.0018982 3.75 1.70983e-06H33.75C35.8211 1.70983e-06 37.5 1.67894 37.5 3.75V26.25C37.5 28.3211 35.8211 30 33.75 30ZM3.75 7.2525V26.25H33.75V7.2525L18.75 17.25L3.75 7.2525ZM5.25 3.75L18.75 12.75L32.25 3.75H5.25Z" fill="white"/>
    <path d="M33.75 30H3.75C1.67893 30 0 28.3211 0 26.25V3.58688C0.0873907 1.57978 1.741 -0.0018982 3.75 1.70983e-06H33.75C35.8211 1.70983e-06 37.5 1.67894 37.5 3.75V26.25C37.5 28.3211 35.8211 30 33.75 30ZM3.75 7.2525V26.25H33.75V7.2525L18.75 17.25L3.75 7.2525ZM5.25 3.75L18.75 12.75L32.25 3.75H5.25Z" fill="black" fillOpacity="0.4"/>
  </svg>
);

const FaceBookSocial = () => (
  <svg width="37" height="38" viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 18.7739C0.00213316 28.0107 6.5604 35.8755 15.4678 37.3231V24.2003H10.8167V18.7739H15.4733V14.6431C15.2652 12.6857 15.918 10.7349 17.2547 9.3198C18.5914 7.90469 20.4735 7.17193 22.3905 7.32024C23.7665 7.34299 25.139 7.4685 26.4972 7.69577V12.3129H24.1798C23.3821 12.2059 22.58 12.4757 21.9996 13.0464C21.4192 13.6171 21.1233 14.4269 21.1952 15.2477V18.7739H26.2753L25.4632 24.2021H21.1952V37.3231C30.8284 35.7639 37.5835 26.7534 36.5668 16.8194C35.55 6.88531 27.1174 -0.494655 17.3778 0.0259204C7.63833 0.546496 0.00154821 8.78538 0 18.7739Z" fill="white"/>
    <path d="M0 18.7739C0.00213316 28.0107 6.5604 35.8755 15.4678 37.3231V24.2003H10.8167V18.7739H15.4733V14.6431C15.2652 12.6857 15.918 10.7349 17.2547 9.3198C18.5914 7.90469 20.4735 7.17193 22.3905 7.32024C23.7665 7.34299 25.139 7.4685 26.4972 7.69577V12.3129H24.1798C23.3821 12.2059 22.58 12.4757 21.9996 13.0464C21.4192 13.6171 21.1233 14.4269 21.1952 15.2477V18.7739H26.2753L25.4632 24.2021H21.1952V37.3231C30.8284 35.7639 37.5835 26.7534 36.5668 16.8194C35.55 6.88531 27.1174 -0.494655 17.3778 0.0259204C7.63833 0.546496 0.00154821 8.78538 0 18.7739Z" fill="black" fillOpacity="0.4"/>
  </svg>
)

const TwitterSocial = () => (
  <svg width="38" height="31" viewBox="0 0 38 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33.0634 4.8071C34.6695 3.84555 35.8713 2.33149 36.4443 0.547598C34.9351 1.44436 33.2839 2.07606 31.562 2.41539C29.1748 -0.113526 25.3924 -0.72895 22.3285 0.913004C19.2645 2.55496 17.6775 6.04779 18.4542 9.4398C12.2722 9.12899 6.51265 6.20466 2.60867 1.39447C0.571255 4.91371 1.61242 9.4125 4.988 11.6754C3.76735 11.6361 2.57378 11.3052 1.50679 10.7101C1.50679 10.7424 1.50679 10.7747 1.50679 10.807C1.5075 14.4729 4.08746 17.6306 7.6755 18.3571C6.54328 18.6656 5.35566 18.711 4.20325 18.4899C5.21232 21.6249 8.09753 23.7726 11.386 23.8367C8.66241 25.9775 5.29881 27.1384 1.83646 27.1327C1.22276 27.1336 0.609544 27.0982 0 27.0268C3.51595 29.2894 7.60808 30.4904 11.7874 30.4861C17.6018 30.5261 23.1896 28.2306 27.3009 24.113C31.4123 19.9955 33.704 14.3995 33.6636 8.57678C33.6636 8.24305 33.6559 7.91112 33.6403 7.58098C35.1461 6.49117 36.4457 5.1411 37.4781 3.5942C36.0752 4.21691 34.5872 4.62574 33.0634 4.8071Z" fill="white"/>
    <path d="M33.0634 4.8071C34.6695 3.84555 35.8713 2.33149 36.4443 0.547598C34.9351 1.44436 33.2839 2.07606 31.562 2.41539C29.1748 -0.113526 25.3924 -0.72895 22.3285 0.913004C19.2645 2.55496 17.6775 6.04779 18.4542 9.4398C12.2722 9.12899 6.51265 6.20466 2.60867 1.39447C0.571255 4.91371 1.61242 9.4125 4.988 11.6754C3.76735 11.6361 2.57378 11.3052 1.50679 10.7101C1.50679 10.7424 1.50679 10.7747 1.50679 10.807C1.5075 14.4729 4.08746 17.6306 7.6755 18.3571C6.54328 18.6656 5.35566 18.711 4.20325 18.4899C5.21232 21.6249 8.09753 23.7726 11.386 23.8367C8.66241 25.9775 5.29881 27.1384 1.83646 27.1327C1.22276 27.1336 0.609544 27.0982 0 27.0268C3.51595 29.2894 7.60808 30.4904 11.7874 30.4861C17.6018 30.5261 23.1896 28.2306 27.3009 24.113C31.4123 19.9955 33.704 14.3995 33.6636 8.57678C33.6636 8.24305 33.6559 7.91112 33.6403 7.58098C35.1461 6.49117 36.4457 5.1411 37.4781 3.5942C36.0752 4.21691 34.5872 4.62574 33.0634 4.8071Z" fill="black" fillOpacity="0.4"/>
  </svg>
)

const LinkedInSocial = () => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.3333 32.2961H11V10.7654H18.3333V14.3538C19.8965 12.4076 22.267 11.2473 24.7958 11.1906C29.3436 11.2153 33.0132 14.8372 33 19.288V32.2961H25.6667V20.1851C25.3734 18.1803 23.6166 16.6928 21.5472 16.6971C20.642 16.7251 19.7876 17.113 19.1811 17.7712C18.5747 18.4294 18.2685 19.3011 18.3333 20.1851V32.2961ZM7.33333 32.2961H0V10.7654H7.33333V32.2961ZM3.66667 7.17692C1.64162 7.17692 0 5.57031 0 3.58846C0 1.60661 1.64162 0 3.66667 0C5.69171 0 7.33333 1.60661 7.33333 3.58846C7.33333 4.54018 6.94703 5.45292 6.25939 6.12589C5.57176 6.79885 4.63913 7.17692 3.66667 7.17692Z" fill="white"/>
    <path d="M18.3333 32.2961H11V10.7654H18.3333V14.3538C19.8965 12.4076 22.267 11.2473 24.7958 11.1906C29.3436 11.2153 33.0132 14.8372 33 19.288V32.2961H25.6667V20.1851C25.3734 18.1803 23.6166 16.6928 21.5472 16.6971C20.642 16.7251 19.7876 17.113 19.1811 17.7712C18.5747 18.4294 18.2685 19.3011 18.3333 20.1851V32.2961ZM7.33333 32.2961H0V10.7654H7.33333V32.2961ZM3.66667 7.17692C1.64162 7.17692 0 5.57031 0 3.58846C0 1.60661 1.64162 0 3.66667 0C5.69171 0 7.33333 1.60661 7.33333 3.58846C7.33333 4.54018 6.94703 5.45292 6.25939 6.12589C5.57176 6.79885 4.63913 7.17692 3.66667 7.17692Z" fill="black" fill-opacity="0.4"/>
  </svg>
);

const EmailIcon = () => (
  <svg width="21px" height="15px" viewBox="0 0 21 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Final" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Contact_v2" transform="translate(-137.000000, -544.000000)" fill="#BBBCBC">
              <g id="Form" transform="translate(118.000000, 423.000000)">
                  <g id="Email-Icon" transform="translate(19.000000, 121.500000)">
                      <path d="M13.20818,7.7828 L10.52018,10.16 L7.83218,7.7744 L1.63298,13.0832 C1.65818,13.0916 1.67498,13.1 1.70018,13.1 L19.28138,13.1 L13.20818,7.7828 Z M0.54098,1.2728 C0.47378,1.424 0.44018,1.5836 0.44018,1.76 L0.44018,11.84 C0.44018,12.0248 0.48218,12.1928 0.55778,12.3524 L6.88298,6.926 L0.54098,1.2728 Z M20.60018,1.76 C20.60018,1.6088 20.56658,1.4744 20.51618,1.34 L14.16578,6.9428 L20.43218,12.428 C20.53298,12.2516 20.60018,12.0584 20.60018,11.84 L20.60018,1.76 Z M10.52018,8.48 L19.51658,0.5336 C19.45778,0.5252 19.39898,0.5 19.34018,0.5 L1.70018,0.5 C1.65818,0.5 1.63298,0.5168 1.59098,0.5252 L10.52018,8.48 Z" id="Fill-1"></path>
                  </g>
              </g>
          </g>
      </g>
  </svg>
);
const MessageIcon = () => (
  <svg width="19px" height="16px" viewBox="0 0 19 16" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Final" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Contact_v2" transform="translate(-141.000000, -645.000000)" fill="#BBBCBC">
              <g id="Form" transform="translate(118.000000, 423.000000)">
                  <path d="M40.35,234.4 L29.5,234.4 L26.4,237.5 L26.4,234.4 L24.85,234.4 C23.9975,234.4 23.3,233.7025 23.3,232.85 L23.3,223.55 C23.3,222.6975 23.9975,222 24.85,222 L40.35,222 C41.2025,222 41.9,222.6975 41.9,223.55 L41.9,232.85 C41.9,233.7025 41.2025,234.4 40.35,234.4 L40.35,234.4 Z M25.625,228.2 L31.825,228.2 L31.825,227.425 L25.625,227.425 L25.625,228.2 Z M25.625,225.875 L39.575,225.875 L39.575,225.1 L25.625,225.1 L25.625,225.875 Z M33.375,228.2 L39.575,228.2 L39.575,227.425 L33.375,227.425 L33.375,228.2 Z M25.625,230.525 L39.575,230.525 L39.575,229.75 L25.625,229.75 L25.625,230.525 Z" id="Message-Icon"></path>
              </g>
          </g>
      </g>
  </svg>
);
const NameIcon = () => (
  <svg width="15px" height="15px" viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Final" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Contact_v2" transform="translate(-140.000000, -449.000000)" fill="#BBBCBC">
            <g id="Form" transform="translate(118.000000, 423.000000)">
                <g id="Name-Icon" transform="translate(22.000000, 26.500000)">
                    <path d="M14.5818603,12.9834597 C14.6420612,13.4449999 14.71564,14.0269418 14.71564,14.0470088 L-0.000133779762,14.0470088 C-0.000133779762,14.0269418 0.0667561012,13.4918228 0.126956994,13.0235936 C0.0466891369,12.9968376 -0.000133779762,12.9232588 -0.000133779762,12.7627231 L-0.000133779762,12.3948287 C-0.000133779762,12.3948287 -0.000133779762,11.4115475 1.83264896,10.528601 C2.7089064,10.1005058 4.03265714,8.98344479 5.9664436,8.60217247 C5.45808051,8.02691949 5.09085506,7.09715015 4.70155595,6.02022307 C4.46877917,5.3847692 4.5082442,4.84965015 4.5082442,4.10048348 C4.5082442,3.53860848 4.42195625,2.63559509 4.54168914,2.13392098 C4.96309539,0.461673958 6.05340045,0.000133779762 7.33768616,0.000133779762 C8.6085939,0.000133779762 9.69889896,0.461673958 10.1203052,2.13392098 C10.260774,2.63559509 10.154419,3.51854152 10.154419,4.10048348 C10.154419,4.84965015 10.1938841,5.3847692 9.97983646,6.02022307 C9.59187515,7.09715015 9.20391384,8.00685253 8.71494881,8.60217247 C10.6688022,8.97006682 11.9664659,10.0804388 12.8694793,10.528601 C14.6955731,11.4115475 14.71564,12.3948287 14.71564,12.3948287 L14.71564,12.7092112 C14.71564,12.8965028 14.6621281,12.9700817 14.5818603,12.9834597" id="Fill-1"></path>
                </g>
            </g>
        </g>
    </g>
  </svg>
)
const ContactTemplate = ({
  pageContext
}) => {
  const { meta, page_contents, og_image, page_slug } = pageContext.metadata;
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const initialValues = {
    email: {
        value: '',
        message: '',
        regex: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g,
        state: ValidStates.NotSet,
    },
    name: {
        value: '',
        message: '',
        regex: /.+/g,
        state: ValidStates.NotSet,
    },
    message: {
        value: '',
        message: '',
        regex: /.+/g,
        state: ValidStates.NotSet,
    },
  };
  const [updateValues, checkState, removeWhiteSpace, values] = useFormHooks(initialValues);
  const encode = (values) => {
      return Object.keys(values)
          .map(
              key => `${encodeURIComponent(key)}=${encodeURIComponent(values[key])}`
          )
          .join('&');
  }
  const handleSubmit = async e => {
    const event = e || window.event;
    ////
    // The following is why microsoft sucks...screw you microsoft
    ////
    if (typeof event.preventDefault === 'function') {
    event.preventDefault();
    }
    if (typeof event.stopPropagation === 'function') {
    event.stopPropagation();
    }
    event.returnValue = false;
    setIsLoading(true);
    try {
        const request = await axios.post('/', encode({
            'form-name': 'vinex-contact',
            name: values.name.value,
            email: values.email.value,
            message: values.message.value,
        }), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        })
        setSubmitted(true);
    } catch (error) {
        console.log(error);
    } finally {
        setIsLoading(false);
    }
    
    return false;
  }

  return (
    <Layout>
      <SEO pathname={page_slug} canonical={page_slug} title={meta.meta_title} description={meta.meta_description} ogImage={og_image.imgix_url || null} />
      <div className="offwhite-container">
        <div className="contact-us-container contact-template">
          {!submitted && (
            <div className="contact-us-text-container">
              <h1>Contact Us</h1>
              <p className="mail-to">If you are interested in learning more about our initiative or want to get involved, please contact <strong>Inez Jabalpurwala</strong>.</p>
              <form name="vinex-contact" data-netlify="true" data-netlify-honeypot="bot-field" id="vinex-contact-form" className="contact-form-container" onSubmit={handleSubmit}>
                  <input type="hidden" name="bot-field" />
                  <RoundedInputField
                      id="name"
                      type="text"
                      value={values.name.value}
                      placeholder="Name"
                      checkState={checkState}
                      icon={<NameIcon />}
                      callback={e => updateValues(e)}
                  />
                  <RoundedInputField
                      id="email"
                      type="text"
                      value={values.email.value}
                      placeholder="Email"
                      autoComplete="email"
                      checkState={checkState}
                      icon={<EmailIcon />}
                      callback={e => removeWhiteSpace(e)}
                  />
                  <RoundedTextArea
                      id="message"
                      type="text"
                      placeholder="Your Message"
                      value={values.message.value}
                      checkState={checkState}
                      icon={<MessageIcon />}
                      callback={e => updateValues(e)}
                  />
                  <div className="contact-button-container">
                    <div/>
                    <DefaultButton
                      title="LEARN MORE"
                      onClick={() => {}}
                      isLoading={isLoading}
                    />
                  </div>
              </form>
              <div className="vinex-social">
                <p>Stay connected with VINEx.</p>
                <div className="vinex-social-container">
                  <a href="mailto:hello@vinex.ca"><EmailSocial /></a>
                  <a href="https://www.facebook.com/VINExploration" target="_blank" rel="noopener noreferrer nofollow"><FaceBookSocial /></a>
                  <a href="https://twitter.com/VINExploration" target="_blank" rel="noopener noreferrer nofollow"><TwitterSocial /></a>
                  <a href="https://www.linkedin.com/company/vinexploration" target="_blank" rel="noopener noreferrer nofollow"><LinkedInSocial /></a>
                </div>
                <p>Sign up for our mailing list.</p>
                <a
                  className="default__button blue"
                  href="https://vinex.us1.list-manage.com/subscribe?u=7538ce0d8d60de3ff5e65d7d5&id=ce0e76a8f6"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  SIGN UP
                </a>
              </div>
              
            </div>
          )}
          {submitted && (
            <div className="contact-us-text-container success">
              <h2>Thank you!</h2>
              <p>Thank you for contacting us. We will get back to you as soon as we can.</p>
              <DefaultButton title="Back to Home" url="/" />
            </div>
          )}
        </div>
      </div>
      
    </Layout>
  );
};

ContactTemplate.propTypes = {
  pageContext: PropTypes.any.isRequired,
}

export default ContactTemplate