import React, { useState } from 'react';
import validate from 'validate.js';
import ValidStates from './ValidStates';

const useFormHooks = (initialValues) => {
  const [values, setValues] = useState(initialValues)
  const checkState = (e) => {
    if (e.currentTarget.id === 'email') {
      const errors = validate({ email: e.currentTarget.value }, {
        email: {
          length: {
            minimum: 5,
            maximum: 256,
          },
          email: {
            message: 'is not valid.',
          },
        },
      });
      if (errors !== undefined) {
        setValues({
          ...values,
          [e.currentTarget.id]: {
            value: e.currentTarget.value,
            state: ValidStates.Invalid,
            message: 'The field you have entered is incorrect. Please try again.',
            regex: values[e.currentTarget.id].regex,
          },
        });
        return;
      }
      setValues({
        ...values,
        [e.currentTarget.id]: {
          value: e.currentTarget.value, state: ValidStates.Valid, message: '', regex: values[e.currentTarget.id].regex,
        },
      });
    }
    const match = e.currentTarget.value.match(values[e.currentTarget.id].regex);
    if (match) {
      setValues({
        ...values,
        [e.currentTarget.id]: {
          value: e.currentTarget.value, state: ValidStates.Valid, message: '', regex: values[e.currentTarget.id].regex,
        },
      });
    } else {
      setValues({
        ...values,
        [e.currentTarget.id]: {
          value: e.currentTarget.value,
          state: ValidStates.Invalid,
          message: 'The field you have entered is incorrect. Please try again.',
          regex: values[e.currentTarget.id].regex,
        },
      });
    }
  };
  const updateValues = (event) => {
    checkState(event);
    setValues({
      ...values,
      [event.currentTarget.id]: {
        value: event.currentTarget.value,
        message: '',
        state: values[event.currentTarget.id].state,
        regex: values[event.currentTarget.id].regex,
      },
    });
  };
  const removeWhiteSpace = (e) => {
    const event = e || window.event;
    const modifiedEvent = event;
    modifiedEvent.currentTarget.value = event.currentTarget.value.replace(/\s/g, '');
    updateValues(modifiedEvent);
  };

  return [updateValues, checkState, removeWhiteSpace, values];
}

export default useFormHooks;