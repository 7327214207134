import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import '../styles/components/DefaultButton.scss';

const DefaultButton = ({
  title,
  url,
  extraClass,
  onClick,
  isLocal,
  isLoading,
}) => {
  return onClick ? (
    <button
      title="Learn more by clicking here"
      className={`default__button ${extraClass}`}
      onClick={() => onClick()}
      aria-label="Learn more by clicking here"
    >
      {isLoading ? <div className="donut" /> : title}
    </button>
    ): (
      <a
        className={`default__button ${extraClass}`}
        href={url}
        title="Learn more by clicking here"
        aria-label="Learn more by clicking here"
        target={isLocal ? '' : '_blank'}
        rel={isLocal ? '' : 'noopener noreferrer nofollow'}
      >
          {isLoading ? <div className="donut" /> : title}
      </a>
    );
};

DefaultButton.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  extraClass: PropTypes.string,
  onClick: PropTypes.func,
  isLocal: PropTypes.bool,
  isLoading: PropTypes.bool,
};

DefaultButton.defaultProps = {
  extraClass: 'blue',
  isLocal: true,
  isLoading: false,
};


export default DefaultButton;
